import { Box, Card, Divider, InlineGrid, LegacyCard, Link, Page, Text, TextField, BlockStack, useBreakpoints, Form, ContextualSaveBar, Button, Select } from '@shopify/polaris';
import { useField, useForm } from '@shopify/react-form';
import { useNotification } from 'context/NotificationContext';
import { useSettings } from 'context/SettingsContext';
import helpers from 'helpers/index';
import __ from 'languages/index';
import { useCreateAssistants, useGetAIModels } from 'queries/ai.query';
import { useUpdateSetting } from 'queries/setting.query';
import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
export default function Embed() {
  const {
    smUp
  } = useBreakpoints();
  const {
    addNotification
  } = useNotification();
  const {
    settings: entities,
    loading
  } = useSettings();
  const {
    mutateAsync: updateEntity,
    error,
    isSuccess: updateSuccess,
    isPending: updating
  } = useUpdateSetting();
  const {
    mutateAsync: getAIModels,
    data: AIMODEL
  } = useGetAIModels();
  useEffect(() => {
    getAIModels();
  }, []);
  const useFields = {
    zalo_secret_id: useField<string>({
      value: entities?.zalo_secret_id ?? '',
      validates: []
    }),
    zalo_app_id: useField<string>({
      value: entities?.zalo_app_id ?? '',
      validates: []
    }),
    _chatgpt_key: useField<string>({
      value: entities?._chatgpt_key ?? '',
      validates: []
    }),
    _chatgpt_model_selected: useField<string>({
      value: entities?._chatgpt_model_selected ?? 'gpt-4o-mini',
      validates: []
    }),
    _chatgpt_assistants_name: useField<string>({
      value: entities?._chatgpt_assistants_name ?? 'Trợ lý giám đốc',
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          zalo_secret_id: values.zalo_secret_id,
          zalo_app_id: values.zalo_app_id,
          _chatgpt_key: values._chatgpt_key,
          _chatgpt_assistants_name: values._chatgpt_assistants_name,
          _chatgpt_model_selected: values._chatgpt_model_selected
        });
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });

  /**
   * Notification
   */

  useEffect(() => {
    if (error) {
      addNotification('error', error.message);
    }
  }, [error]);
  useEffect(() => {
    if (updateSuccess) addNotification('info', __('updated_successfully'));
  }, [updateSuccess]);
  const {
    mutateAsync: createAssistants,
    isPending: creatingAssistant,
    isSuccess: createAssistantSuccess
  } = useCreateAssistants();
  const createAssistantsCallback = useCallback(async () => {
    // xóa thread đi không là lỗi...
    helpers.cookie_delete('ai_thread');
    try {
      await createAssistants();
    } catch (e) {}
  }, []);
  useEffect(() => {
    if (createAssistantSuccess) addNotification('info', __('updated_successfully'));
  }, [createAssistantSuccess]);
  return <>
      <Helmet>
        <title>Mã nhúng và kết nối</title>
      </Helmet>
      <Page title="Mã nhúng và kết nối">
        <Helmet>
          <title>Cài đặt mã nhúng và kết nối</title>
        </Helmet>

        <BlockStack gap={{
        xs: '800',
        sm: '400'
      }}>
          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Mã nhúng cho web
                </Text>
                <Text as="p" variant="bodyMd">
                  Mã Passport.js là mã quan trọng, giúp các chức năng bên dưới hoạt động trơn tru và bảo vệ bạn khỏi Spambot tấn công.
                </Text>
              </BlockStack>
            </Box>
            <LegacyCard sectioned>
              <Text as="p" variant="bodySm" tone="subdued">
                Nhúng mã sau đây lên landing page của bạn, trước thẻ <code>&lt;/head&gt;</code> và để ở mọi trang.
              </Text>
              <TextField label=" " value={`<script crossorigin="anonymous" type="application/javascript" src="${process.env.REACT_APP_PUBLIC_URL}/passport.js"></script>`} onChange={() => {}} multiline={2} autoComplete="off" />
              <br />
              <Text as="p" variant="bodySm" tone="subdued">
                Nhúng mã sau đây lên landing page của bạn, trước thẻ <code>&lt;/body&gt;</code> và để ở trang bạn muốn tracking người dùng.
              </Text>
              <TextField label=" " value={`<script>
        var backend_url = '${process.env.REACT_APP_BACKEND_URL}/activity/web_activity'; 
        var requestee_option = {};
    </script>
    <script crossorigin="anonymous" defer="defer" type="application/javascript" src="${process.env.REACT_APP_PUBLIC_URL}/requestee.js"></script>`} onChange={() => {}} multiline={2} autoComplete="off" />
              <br />
              <Text as="p" variant="bodySm" tone="subdued">
                Nếu bạn muốn định nghĩa trang đích để tracking tốt hơn, bạn có thể đặt dữ liệu vào biến <br />
                <code>requestee_option.activity_target = 'YOUR_DEFINITION'; // định nghĩa tên trang đích </code> <br />
                <code>requestee_option.activity_referrer = 'YOUR_DEFINITION'; // định nghĩa trang giới thiệu </code> <br />
                Khi đó bạn có thể dễ dàng định nghĩa và lọc được người dùng truy cập một cách dễ dàng.
              </Text>
            </LegacyCard>
          </InlineGrid>

          {smUp ? <Divider /> : null}

          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Contact form
                </Text>
                <Text as="p" variant="bodyMd">
                  Form liên hệ để kết nối giữa khách hàng và doanh nghiệp thông qua website hoặc app.
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <BlockStack gap={'200'}>
                <Text as="h3" variant="headingSm">
                  Dành cho website
                </Text>
                <Text as="p">Bạn thực hiện truy vấn như sau:</Text>
                <pre>
                  {`URL: ${process.env.REACT_APP_BACKEND_URL}/contactform
METHOD: POST
BODY: {
    contactform_title: String,
    contactform_content: String,
    contactform_email: String,
    // hoặc
    contactform_name: String
}`}
                </pre>
                <Text as="p">
                  {' '}
                  Để có thể lưu một Contact Form trên website, bạn cần có mã nhúng ở phần 1. Mã nhúng sẽ giúp định danh khách hàng kể cả khi họ không
                  đăng nhập.{' '}
                </Text>

                <Text as="h3" variant="headingSm">
                  Dành cho mobile app
                </Text>
                <Text as="p">
                  Bạn thực hiện truy vấn như trên phiên bản web, tuy nhiên yêu cầu người dùng đăng nhập vì trên mobile app không sử dụng được thư viện
                  Passport.js
                </Text>
              </BlockStack>
            </Card>
          </InlineGrid>

          {smUp ? <Divider /> : null}

          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Kết nối
                </Text>
                <Text as="p" variant="bodyMd">
                  Đây là các cổng kết nối từ các phần mềm ngoài vào trong CRM
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <BlockStack gap={'200'}>
                <Text as="h3" variant="headingSm">
                  Dành cho Cổng thanh toán
                </Text>
                <Text as="p">
                  Cổng thanh toán có thể thực hiện request tới endpoint này khi thanh toán thành công. Để nhận thông tin thanh toán, vui lòng xem
                  trong phần <Link url={'/incoming_webhook'}>incoming webhook</Link>. Bạn tìm tới webhook có tên <i>Payment Gate</i>.
                </Text>
              </BlockStack>

              <br />
              <br />
              <Form onSubmit={submit}>
                <BlockStack gap={'200'}>
                  <Text as="h3" variant="headingSm">
                    Dành cho ZNS (Zalo Notification Service)
                  </Text>
                  <TextField label="ID ứng dụng" autoComplete="off" {...fields.zalo_app_id} />
                  <TextField label="Khóa bí mật của ứng dụng" autoComplete="off" {...fields.zalo_secret_id} />
                  <TextField label="Đường link callback" readOnly autoComplete="off" value={window.location.origin + `/zaloZNSApp_callback`} helpText={'Đây là đường link "Thiết lập đường dẫn yêu cầu cấp quyền" trong Zalo For Deverlopment. Thêm đường dẫn này vào trong ứng dụng của Zalo để đường link hoạt động.'} />
                  <Text as="p">
                    Khi bạn đã có ID ứng dụng và Khóa bí mật, bạn cần cấp quyền cho ứng dụng của mình. Click vào nút bên dưới để cấp quyền.
                  </Text>
                  <br />
                  <Button target="_self" url={`https://oauth.zaloapp.com/v4/oa/permission?app_id=${entities?.zalo_app_id}&redirect_uri=${window.location.origin}/zaloZNSApp_callback`}>
                    Xin cấp Quyền
                  </Button>
                </BlockStack>
              </Form>
            </Card>
          </InlineGrid>

          {smUp ? <Divider /> : null}

          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  ChatGPT key
                </Text>
                <Text as="p" variant="bodyMd">
                  Key của dịch vụ openai.com
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <BlockStack gap={'400'}>
                <TextField label="Key kết nối" autoComplete="off" {...fields._chatgpt_key} />
                <TextField label="Đặt tên cho trợ lý" autoComplete="off" {...fields._chatgpt_assistants_name} />
                <TextField label="Mã trợ lý" connectedRight={<Button onClick={() => createAssistantsCallback()} loading={creatingAssistant}>
                      Tạo một trợ lý
                    </Button>} autoComplete="off" readOnly value={entities?._chatgpt_assistants || 'Chưa có'} />
                <Select options={AIMODEL?.map(models => ({
                label: models.id,
                value: models.id
              }))} label={'Chọn Models'} {...fields._chatgpt_model_selected} />
              </BlockStack>
            </Card>
          </InlineGrid>

          {smUp ? <Divider /> : null}
        </BlockStack>
        <br />
        <br />
        <br />
        <br />
      </Page>

      {dirty && <ContextualSaveBar message="Thay đổi chưa được lưu" saveAction={{
      onAction: () => submit(),
      loading: updating
    }} discardAction={{
      onAction: () => resetForm()
    }} />}
    </>;
}