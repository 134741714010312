import { Box, Card, Divider, InlineGrid, Link, Page, Text, TextField, BlockStack, useBreakpoints, Button, ContextualSaveBar, Tooltip } from '@shopify/polaris';
import { useField, useForm } from '@shopify/react-form';
import { useNotification } from 'context/NotificationContext';
import { useSettings } from 'context/SettingsContext';
import __ from 'languages/index';
import { useUpdateSetting } from 'queries/setting.query';
import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { RefreshIcon } from '@shopify/polaris-icons';
import __helpers from 'helpers/index';
export default function Pbx() {
  const {
    addNotification
  } = useNotification();
  const {
    smUp
  } = useBreakpoints();
  const {
    settings: entities
  } = useSettings();
  const {
    mutateAsync: updateEntity,
    error,
    isSuccess: updateSuccess,
    isPending: updating
  } = useUpdateSetting();
  const useFields = {
    _pbx_whitelist_ips: useField<string>({
      value: entities?._pbx_whitelist_ips ?? '',
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          /** ipinfo.io */
          _pbx_whitelist_ips: values._pbx_whitelist_ips
        });
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  const changePhoneWebhookHash = useCallback(async () => {
    //_phonelog_webhook_hash
    let newHash = __helpers.getRandomHash(40);
    await updateEntity({
      _phonelog_webhook_hash: newHash
    });
  }, []);
  const changePhoneContactQueryHash = useCallback(async () => {
    let newHash = __helpers.getRandomHash(20);
    await updateEntity({
      _phonelog_contact_query_hash: newHash
    });
  }, []);

  /**
   * Notification
   */

  useEffect(() => {
    if (error) {
      addNotification('error', error.message);
    }
  }, [error]);
  useEffect(() => {
    if (updateSuccess) addNotification('info', __('updated_successfully'));
  }, [updateSuccess]);
  return <>
      <Helmet>
        <title>Cài đặt kết nối tổng đài PBX</title>
      </Helmet>
      <Page title="Cài đặt kết nối tổng đài PBX">
        <BlockStack gap={{
        xs: '800',
        sm: '400'
      }}>
          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Bảo mật
                </Text>
                <Text as="p" variant="bodyMd">
                  Hạn chế truy cập từ các địa chỉ IP không mong muốn.
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <BlockStack gap="400">
                <Text as="p">
                  Danh sách IP được phép truy cập, phân tách bởi dấu phẩy(,). Nếu không có IP nào được nhập vào thì sẽ cho phép tất cả các IP.
                </Text>
                <TextField label="IP được phép truy cập" helpText={``} autoComplete="off" multiline={false} {...fields._pbx_whitelist_ips} />
              </BlockStack>
            </Card>
          </InlineGrid>

          {smUp ? <Divider /> : null}
          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  SMS
                </Text>
                <Text as="p" variant="bodyMd">
                  Chức năng nhắn tin, nhận tin nhắn ngay từ CRM.
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <BlockStack gap={'200'}>
                <Text as="h3" variant="headingSm">
                  Dành cho SMS Gate
                </Text>
                <Text as="p">
                  Để nhận SMS từ SMS-Box, vui lòng xem trong phần <Link url={'/incoming_webhook'}>incoming webhook</Link>. Bạn tìm tới webhook có tên{' '}
                  <i>SMS Gate</i>.
                </Text>
              </BlockStack>
            </Card>
          </InlineGrid>

          {smUp ? <Divider /> : null}

          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Danh bạ
                </Text>
                <Text as="p" variant="bodyMd">
                  Cấu hình truy vấn danh bạ cho các loại tổng đài
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <BlockStack gap={'200'}>
                <Text as="h3" variant="headingSm">
                  Query danh bạ cho tổng đài
                </Text>
                <Text as="p">Để kết nối tổng đài cho phần danh bạ, cài đặt truy vấn tới đường dẫn sau:</Text>
                <TextField label="Contact Query URL" value={`${process.env.REACT_APP_BACKEND_URL}/phonelog/contact-query/${entities?._phonelog_contact_query_hash || '{Bấm tạo mã để lấy mã}'}`} connectedRight={<Tooltip content={'Tạo mã truy cập mới'}>
                      <Button icon={RefreshIcon} onClick={() => changePhoneContactQueryHash()}></Button>
                    </Tooltip>} autoComplete="off" readOnly />
                <Text as="p">
                  Truy vấn được chấp nhận là GET, với các tham số sau:
                  <ul>
                    <li>
                      <b>phonenumber</b>: Số điện thoại cần tìm kiếm. Ví dụ: 0912345678
                    </li>
                    <li>
                      <b>source</b>: Nguồn tổng đài, bạn có thể kết nối nhiều tổng đài vào một endpoint và phân chia chúng bằng source.
                    </li>
                    <li>
                      <b>format</b>: Định dạng trả về. Có thể là <i>json</i>, <i>plaintext</i>. Mặc định là <i>plaintext</i>.
                    </li>
                  </ul>
                </Text>
                <Text as="p">
                  Ví dụ:{' '}
                  <code>{`${process.env.REACT_APP_BACKEND_URL}/phonelog/contact-query/${entities?._phonelog_contact_query_hash}?phonenumber=0912345678&source=pbx`}</code>
                </Text>
                <Text as="p">
                  Nếu bạn chọn định dạng JSON, Định dạng trả về sẽ là một danh sách các trường trong danh bạ, ví dụ như sau:
                  <pre>
                    {`{
"contacts": [
    {
      "name": "Nguyễn Văn A",
      "number": "0909123456",
      "email": "a.nguyen@example.com",
      "company": "Công ty A"
    },
  ]
}`}
                  </pre>
                </Text>
                <Text as="p">
                  Định dạng mặc định là plaintext, khi truy vấn thành công, hệ thống có thể trả về số điện thoại (nếu chưa có trong danh bạ) hoặc tên
                  và số điện thoại (nếu đã có trong danh bạ). Ví dụ: <code>Nguyễn Văn A - 0912345678</code>
                </Text>
              </BlockStack>
            </Card>
          </InlineGrid>

          {smUp ? <Divider /> : null}

          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Call Journal
                </Text>
                <Text as="p" variant="bodyMd">
                  Nhật ký cuộc gọi và ghi âm
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <BlockStack gap={'400'}>
                <Text as="h3" variant="headingSm">
                  Đây là chức năng quản lý nhật ký cuộc gọi trên CRM, để sử dụng chức năng này, bạn cần cài đặt trên tổng đài để trỏ lịch sử cuộc gọi
                  về CRM. Tùy thuộc vào loại tổng đài mà bạn đang sử dụng.
                </Text>

                <TextField label="Call Journal Endpoint (POST)" value={`${process.env.REACT_APP_BACKEND_URL}/phonelog/webhook_callback/${entities?._phonelog_webhook_hash || '{Bấm tạo mã để lấy mã}'}`} connectedRight={<Tooltip content={'Tạo mã truy cập mới'}>
                      <Button icon={RefreshIcon} onClick={() => changePhoneWebhookHash()}></Button>
                    </Tooltip>} autoComplete="off" readOnly />

                <Text as="h3" variant="headingSm">
                  Với FreePBX CRM Link
                </Text>
                <Text as="p">
                  Để sử dụng chức năng này, bạn cần cài đặt FreePBX CRM Link module trên tổng đài FreePBX của bạn. Bạn có thể tải về tại{' '}
                  <a href="https://www.freepbx.org/add-on/customer-relationship-management-crm-link/" rel="noreferrer" target="_blank">
                    đây
                  </a>
                  . Sau khi cài đặt xong, bạn cần cấu hình đường dẫn webhook trong FreePBX để trỏ về "Call Journal Endpoint" cấu hình phía trên.
                </Text>
                <Text as="p" tone="subdued">
                  Lưu ý: Bạn đăng ký một webhook với direction "both" tới url Call Journal
                </Text>

                <Text as="h3" variant="headingSm">
                  Với tổng đài Yeastar
                </Text>
                <Text as="p">
                  Để sử dụng chức năng này, bạn cần cài đặt Yeastar Call Journal trên tổng đài Yeastar của bạn. Tài liệu có thể xem{' '}
                  <a href="https://help.yeastar.com/en/p-series-appliance-edition/integrations/custom-crm-integration/add-a-crm-integration-template.html#add-a-crm-integration-template__section_xmw_zbt_q2c" rel="noreferrer" target="_blank">
                    tại đây
                  </a>
                </Text>
                <Text as="p">Mẫu cài đặt cho Yeastar P-Series</Text>
                <TextField autoComplete="off" multiline={true} label="Yeastar P-Series" value={`{
  "direction": "{{.Communication_Type}}",
  "source": "{{.CallerNumber}}",
  "destination": "{{.CalleeNumber}}",
  "disposition": "{{.CallDisposition}}",
  "recordingfile": "{{.RecordPath}}",
  "duration": "{{.CallDuration}}",
  "uuid": "{{.CrmId}}"
}`} labelHidden />
              </BlockStack>
            </Card>
          </InlineGrid>

          {/* {smUp ? <Divider /> : null}
           <InlineGrid columns={{ xs: '1fr', md: '2fr 5fr' }} gap="400">
            <Box as="section" paddingInlineStart={{ xs: '400', sm: '0' }} paddingInlineEnd={{ xs: '400', sm: '0' }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  ------
                </Text>
                <Text as="p" variant="bodyMd">
                  ----- ----- ----
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">------</Card>
           </InlineGrid> */}

          {/** END */}
        </BlockStack>

        <br />
        <br />
        <br />
        <br />
      </Page>

      {dirty && <ContextualSaveBar message="Thay đổi chưa được lưu" saveAction={{
      onAction: () => submit(),
      loading: updating
    }} discardAction={{
      onAction: () => resetForm()
    }} />}
    </>;
}