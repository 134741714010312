import { HomeIcon, SettingsIcon, BlogIcon, FolderIcon, MenuHorizontalIcon, NotificationIcon, AffiliateIcon, ChartVerticalFilledIcon, PersonIcon, IncomingIcon, PackageIcon, CursorBannerIcon, PersonalizedTextIcon, LockFilledIcon, EmailIcon, EmailFollowUpIcon, EmailNewsletterIcon, StarIcon, PhoneIcon, TeamIcon, DeliveryIcon, ChartLineIcon, BookIcon } from '@shopify/polaris-icons';
import { Navigation, Text, BlockStack } from '@shopify/polaris';
import { useLocation } from 'react-router-dom';
import { default as packageInformation } from '../../package.json';
import helpers from 'helpers/index';
import Capacity from 'components/Capacity';
import { useAuth } from 'context/AuthContext';
import __helpers from 'helpers/index';
const generalMenu: any[] = [{
  url: '/',
  label: 'Trang chủ',
  icon: HomeIcon,
  exactMatch: true,
  permissions: [] // Quyền cần để truy cập mục này
}];
const saleMenus = [{
  url: '/customer',
  label: 'Khách hàng',
  icon: PersonIcon,
  permissions: ['manage_customers', 'view_customers', 'edit_customers'] // Quyền cần để truy cập mục này
}, {
  url: '/phonelog',
  label: 'Nhật ký cuộc gọi',
  icon: PhoneIcon,
  permissions: ['manage_customers', 'view_customers', 'edit_customers'] // Quyền cần để truy cập mục này
}, {
  url: '/list-drivers',
  label: 'Danh sách tài xế',
  icon: TeamIcon,
  permissions: ['manage_customers', 'view_customers', 'edit_customers'] // Quyền cần để truy cập mục này
}, {
  url: '/vehicle',
  label: 'Phương tiện',
  icon: DeliveryIcon,
  permissions: ['manage_customers', 'view_customers', 'edit_customers'] // Quyền cần để truy cập mục này
}, {
  url: '/ticket',
  label: 'Danh sách vé',
  icon: AffiliateIcon,
  permissions: [''] // Quyền cần để truy cập mục này
}, {
  url: '/journey',
  label: 'Hành trình',
  icon: ChartLineIcon,
  permissions: ['manage_customers', 'view_customers', 'edit_customers'] // Quyền cần để truy cập mục này
}, {
  url: '/book-tickets',
  label: 'Quản lý vé',
  icon: BookIcon,
  permissions: ['manage_customers', 'view_customers', 'edit_customers'] // Quyền cần để truy cập mục này
}];
const supporterMenus: any[] = [{
  url: '/contactform',
  label: 'Contact Form',
  icon: PersonalizedTextIcon,
  permissions: ['manage_contact_forms'] // Quyền cần để truy cập mục này
}];
const advanceUserItems = [{
  url: '/banner',
  label: 'Banner',
  icon: CursorBannerIcon,
  permissions: ['manage_banners'] // Quyền cần để truy cập mục này
}, {
  url: '/notification',
  label: 'Thông báo',
  icon: NotificationIcon,
  permissions: ['manage_notifications'] // Quyền cần để truy cập mục này
}, {
  url: '/webmail',
  label: 'Webmail',
  icon: EmailIcon,
  permissions: ['access_webmail'],
  // Quyền cần để truy cập mục này
  subNavigationItems: [{
    url: '/webmail',
    icon: EmailFollowUpIcon,
    label: 'Sent',
    permissions: ['view_sent_webmail'] // Quyền cần để truy cập mục con này
  }, {
    url: '/webmail/incoming',
    icon: EmailNewsletterIcon,
    label: 'Incoming',
    permissions: ['view_incoming_webmail'] // Quyền cần để truy cập mục con này
  }]
}, {
  url: '/posts',
  label: 'Bài đăng và trang',
  icon: BlogIcon,
  permissions: ['manage_posts', 'edit_posts'],
  // Quyền cần để truy cập mục này
  subNavigationItems: [{
    url: '/posts',
    label: 'Danh sách bài đăng',
    permissions: ['manage_posts', 'edit_posts'] // Quyền cần để truy cập mục này
  }, {
    url: '/posts/category',
    label: 'Chuyên mục',
    permissions: ['manage_post_categories'] // Quyền cần để truy cập mục con này
  }]
}, {
  url: '/media',
  label: 'Media',
  icon: FolderIcon,
  permissions: ['manage_media'] // Quyền cần để truy cập mục này
}];

/**
 * For admin
 */
const adminMenuItems = [{
  url: '/analytics',
  label: 'Báo cáo',
  icon: ChartVerticalFilledIcon,
  subNavigationItems: [{
    url: '/analytics/customer',
    label: 'Báo cáo khách hàng'
  }, {
    url: '/analytics/staff',
    label: 'Hiệu năng nhân viên'
  }]
}, {
  url: '/incoming_webhook',
  label: 'Incoming Webhook',
  icon: IncomingIcon
}, {
  url: '/outgoing_webhook',
  label: 'Outgoing Webhook',
  icon: PackageIcon
}, {
  url: '/settings/',
  label: 'Cài đặt chung',
  icon: SettingsIcon,
  subNavigationItems: [{
    url: '/settings',
    icon: MenuHorizontalIcon,
    label: 'System'
  }, {
    url: '/account_setting',
    icon: MenuHorizontalIcon,
    label: 'Tài khoản'
  }, {
    url: '/backup',
    icon: MenuHorizontalIcon,
    label: 'Back up'
  }, {
    url: '/pbx',
    icon: MenuHorizontalIcon,
    label: 'Tổng đài'
  }, {
    url: '/embed',
    icon: MenuHorizontalIcon,
    label: 'Mã nhúng & kết nối'
  }, {
    url: '/media_setting',
    icon: MenuHorizontalIcon,
    label: 'Media'
  }, {
    url: '/keymap',
    icon: MenuHorizontalIcon,
    label: 'Keymap'
  }]
}, {
  url: '/email_setting/',
  label: 'Cài đặt Email',
  icon: EmailIcon,
  subNavigationItems: [{
    url: '/email_setting',
    icon: MenuHorizontalIcon,
    label: 'Tài khoản'
  }, {
    url: '/email_template',
    icon: MenuHorizontalIcon,
    label: 'Biểu mẫu'
  }]
}, {
  url: '/security/',
  label: 'Bảo mật',
  icon: LockFilledIcon
}, {
  url: '/users/',
  label: 'Người dùng',
  icon: PersonIcon,
  subNavigationItems: [{
    url: '/users',
    icon: MenuHorizontalIcon,
    label: 'Tất cả tài khoản'
  }, {
    url: '/user_group',
    icon: MenuHorizontalIcon,
    label: 'Nhóm người dùng'
  }]
}];

/**
 * START ...
 * @returns
 */
export default function Sidebar() {
  const location = useLocation();
  const version = process.env.REACT_APP_VERSION;
  const polarisVersion = packageInformation['dependencies']['@shopify/polaris'] || '';
  const {
    user: current_user_data
  } = useAuth();
  const {
    capacities,
    user_role
  } = current_user_data;

  /** Kiểm tra nếu người dùng hiện tại có quyền */
  const isAllowed = (current_user_can: string): boolean => {
    if (current_user_can === '') return true; // khi không yêu cầu quyền gì ...
    switch (user_role) {
      case 'super_admin':
        return true;
      case 'admin':
        return true;
      default:
        if (user_role === current_user_can) {
          return true;
        } else {
          if (__helpers.isObject(capacities)) for (let key in capacities) {
            let value = capacities[key];
            if (Array.isArray(value) && value.includes(current_user_can)) {
              return true;
            }
          }
        }
        break;
    }
  };

  /**
   * capacities có dữ liệu dạng
   {
    tên nhóm quyền : [các quyền]
   },...
   */
  const filterMenusByPermission = (menus: any[]) => {
    return menus.map(menu => {
      const hasPermission = helpers.isEmpty(menu.permissions) ? true : menu.permissions.some((permission: any) => isAllowed(permission));
      if (!hasPermission) return null;

      // Lọc subNavigationItems nếu có
      const subNavigationItems = menu.subNavigationItems?.filter((subMenu: any) => helpers.isEmpty(subMenu.permissions) ? true : subMenu.permissions.some((permission: any) => isAllowed(permission)));
      return {
        ...menu,
        subNavigationItems // Gán lại subNavigationItems sau khi lọc
      };
    }).filter(Boolean); // Loại bỏ các phần tử null
  };

  // Sử dụng hàm
  const _check_permission_generalMenu = filterMenusByPermission(generalMenu);
  const _check_permission_saleMenus = filterMenusByPermission(saleMenus);
  const _check_permission_supporterMenus = filterMenusByPermission(supporterMenus);
  const _check_permission_advanceUserItems = filterMenusByPermission(advanceUserItems);
  return <Navigation location={location.pathname}>
      <Navigation.Section items={_check_permission_generalMenu} />
      {!helpers.isEmpty(_check_permission_saleMenus) && <Navigation.Section title="Dành cho Sale" separator items={_check_permission_saleMenus} />}

      {!helpers.isEmpty(_check_permission_supporterMenus) && <Navigation.Section title="Dành cho Supporter" separator items={_check_permission_supporterMenus} />}

      {!helpers.isEmpty(_check_permission_advanceUserItems) && <Navigation.Section title="Nâng cao" separator items={_check_permission_advanceUserItems} />}

      <Capacity current_user_can="admin">
        <Navigation.Section title="Quản trị CRM" separator items={adminMenuItems} />
      </Capacity>

      <Navigation.Section fill items={[]} />

      <BlockStack align="center" inlineAlign="center" gap={'200'}>
        <Text as="p" variant="bodyXs" tone="subdued">
          XQ CRM &copy;2024, version {version}
          <br />
          build: {packageInformation.build_version}
          <br />
          {helpers.isDev() && `Polaris Framework ver ${polarisVersion}`}
        </Text>
        <div style={{
        marginBottom: 5,
        marginTop: 5
      }}></div>
      </BlockStack>
    </Navigation>;
}